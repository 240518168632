import React from "react"
import { CircularProgress, Backdrop } from "@mui/material"

function Loader({ isLoading, colorProp }) {
    return (
        <Backdrop
            open={isLoading || false}
            sx={{ zIndex: 1, background: "rgba(0, 0, 10, 0.1)" }}
        >
            <div>
                <CircularProgress
                    color="inherit"
                    sx={{ color: colorProp ? "white" : "black" }}
                />
            </div>
        </Backdrop>
    )
}

export default Loader
