/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import SideNavbar from "./SideNavBar"
import Header from "./HeaderSection"
import Logout from "../modules/Profile/Logout"
import { Grid } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import BottomNavbar from "./BottomSideNavBar"
import IconButton from "@mui/material/IconButton"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ClearIcon from "@mui/icons-material/Clear"
import ChatBotIcon from "../modules/Enquiry/chatbot/chatBotIcon"
import { styled, useTheme } from "@mui/material/styles"
import {
    setOpenSide,
    setchatBot,
    setTargetValue
} from "../../redux/slices/navSlice"
import ModalSurvey from "../common/ModalSurvey/ModalSurvey"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useLocation } from "react-router-dom"
import RMPerfomance from "../modules/RMPerfomance/RMPerfomance"
import { setSurveyId } from "../../redux/slices/navSlice"
import GaiaPageLayout from "./ChatGpt/Layout"

const drawerWidth = "75%"

const Layout = ({ children }) => {
    const chat = useSelector((state) => state.nav.chatBot)
    const openSide = useSelector((state) => state.nav.openSide)
    const surveyId = useSelector((state) => state.nav.surveyId)
    const theme = useTheme()
    const user = JSON.parse(window.localStorage.getItem("user"))
    const modalValue = useSelector((state) => state.nav.modalState)
    const openDrawer = useSelector((state) => state.nav.openDrawer)
    const enableSurvey = user?.financialSurveyCompleted
    const userRights = user?.userRights
    const chatWithRM = userRights.find(
        (item) => item.module === "Chat with RM" && item.create === true
    )
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const isXl = useMediaQuery("(min-width:1536px)")
    const isMd = useMediaQuery("(max-width:1024px)")
    const isSm = useMediaQuery("(max-width:700px)")
    const isXs = useMediaQuery(theme.breakpoints.down("sm"))
    const isReportsPage = pathname.includes("/reports")
    const [displayHeader, setDisplayHeader] = React.useState(false)

    const handleSideClose = () => {
        dispatch(setOpenSide(false))
    }

    let userRole = user?.userTypeId?.description
    let paymentStatusPage =
        window?.location?.pathname?.includes("paymentSuccess") ||
        window?.location?.pathname?.includes("paymentFailed")

    if (window.location.href.includes("/chatgpt")) {
        dispatch(setchatBot(true))
        dispatch(setTargetValue("ChatGpt"))
    } else if (window.location.href.includes("/kairosbot")) {
        dispatch(setTargetValue("llm"))
        dispatch(setchatBot(true))
    }

    useEffect(() => {
        if (!enableSurvey) {
            //initially modal trigger depends on user completeion
            dispatch(
                setSurveyId({ surveyId: user?.surveyId, refreshAPI: true })
            )
        }
        // eslint-disable-next-line
        setTimeout(() => {
            setDisplayHeader(true)
        }, 3000) // eslint-disable-next-lint
    }, [dispatch, enableSurvey, user?.surveyId])

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
        position: "absolute",
        right: "0px"
    }))

    const getOverflowValue = () => {
        if (isMd) {
            switch (true) {
                case pathname.includes("viewuser"):
                    return "hidden"
                case pathname.includes("viewService"):
                    return "auto"
                default:
                    return "auto"
            }
        } else {
            return "auto"
        }
    }

    function getWindowWidth() {
        return window.innerWidth
    }

    return (
        <>
            {chat === false ? (
                <Grid container>
                    {/** Layout for non Gaia screens */}
                    {surveyId !== null && <ModalSurvey />}

                    {/** Drawer, only display for mobile view */}
                    <Drawer
                        sx={{
                            border: "1px solid #EBEBEB",
                            width: drawerWidth,
                            display: { sm: "none" },
                            flexShrink: 0,
                            "& .MuiDrawer-paper": {
                                width: drawerWidth,
                                boxSizing: "border-box"
                            }
                        }}
                        variant="persistent"
                        anchor="left"
                        open={openSide}
                    >
                        <DrawerHeader>
                            <IconButton
                                onClick={handleSideClose}
                                sx={{ top: "10px" }}
                            >
                                {theme.direction === "ltr" ? (
                                    <ClearIcon
                                        sx={{
                                            color: "black",
                                            postion: "absolute"
                                        }}
                                    />
                                ) : (
                                    <ChevronRightIcon />
                                )}
                            </IconButton>
                        </DrawerHeader>
                        <SideNavbar />
                    </Drawer>

                    {/** Side Navbar */}
                    <Grid
                        width={openDrawer ? "251px" : "81px"}
                        display={isXs ? "none" : "block"}
                    >
                        <Grid
                            id="side-navbar-container"
                            display={{ xs: "none", sm: "flex" }}
                            sx={{
                                height: "100vh",
                                borderRight: "1px solid #EBEBEB",
                                backgroundColor: "white",
                                maxWidth: openDrawer ? "801px" : "81px"
                            }}
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Grid>
                                {" "}
                                <SideNavbar />{" "}
                            </Grid>
                            <Grid>
                                {" "}
                                {displayHeader && !isSm && (
                                    <BottomNavbar />
                                )}{" "}
                            </Grid>
                        </Grid>
                    </Grid>

                    {/** Main Content */}
                    <Grid
                        id="main-content-item"
                        width={
                            isXs
                                ? "100%"
                                : openDrawer
                                  ? "calc(100% - 251px)"
                                  : "calc(100% - 81px)"
                        }
                    >
                        {/** Display header only on small displays */}
                        <Grid sx={{ display: { xs: "block", sm: "none" } }}>
                            <Header />
                        </Grid>

                        {/** Main Content block */}
                        <Grid
                            id="main-content-grid"
                            sx={{
                                top: "5px",
                                padding: "5px",
                                overflowY: getOverflowValue(),
                                overflowX: "hidden",
                                height: {
                                    xs: "90vh",
                                    sm: "100vh",
                                    md: "100vh",
                                    lg: "100vh"
                                },
                                position: "relative",
                                backgroundColor: paymentStatusPage
                                    ? "white"
                                    : "transparent",
                                marginBottom: "50px"
                            }}
                        >
                            <Grid
                                sx={{
                                    maxWidth: isReportsPage ? "none" : "1440px",
                                    width: "100%",
                                    margin: "0 auto"
                                }}
                            >
                                {children}
                            </Grid>
                            <Logout />
                        </Grid>

                        {modalValue && <RMPerfomance modalValue={modalValue} />}
                    </Grid>

                    {user &&
                        userRole !== "Entity RM" &&
                        userRole !== "Kairos Admin" &&
                        userRole !== "Entity Admin" &&
                        chatWithRM && <ChatBotIcon />}
                </Grid>
            ) : (
                <GaiaPageLayout>{children}</GaiaPageLayout>
            )}
        </>
    )
}

Layout.propTypes = {}

Layout.defaultProps = {}

export default Layout
