import { Formik, Form } from "formik"

import * as Yup from "yup"
import FormWrapper from "../../components/FormWrapper"
import FormField from "../../components/FormField"
import { Box } from "@mui/material"
import { SelectWrapper, TextFieldWrapper } from "components/common/Ui/Form"
import { useDispatch, useSelector } from "react-redux"
import FormAction from "../../components/FormAction"
import { goToNextPersonalDetails } from "redux/slices/selfOnboardSlice"
import { useSelfOnboardDropdown } from "../../hooks/useOnboardDropdown"
import { useCallback } from "react"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import {
    fetchCityByState,
    fetchStateByCountry,
    updateSelfOnboardProspect
} from "services/prospectsServices"
import { toast } from "react-toastify"
import { setCities, setStates } from "redux/slices/selfOnboardSlice"

const title = "Personal details"
const subtitle =
    "Please let us know what type of investor you are. This helps us tailor the onboarding experience to gather the right information based on your investor profile."

const validationSchema = Yup.object({
    first_name: Yup.string().required("First name required"),
    last_name: Yup.string().required("Last name required"),
    dob: Yup.date()
        .typeError("Valid date of birth required")
        .required("Date of birth required"),
    mobile_country_code: Yup.string().required("Required"),
    mobile: Yup.string()
        .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits")
        .required("Mobile number required"),
    address_1: Yup.string().required("Address Line 1 required"),
    address_2: Yup.string().required("Address Line 2 required"),
    country_id: Yup.string().required("Country required"),
    state_id: Yup.string().required("State required"),
    postal_code: Yup.string()
        .matches(/^\d{5,6}$/, "Postal code must be 5 or 6 digits")
        .required("Postal code required")
})

export default function PersonalDetails() {
    const { get } = useSelfOnboardApi()
    const { post, isLoading } = useSelfOnboardApi()
    useSelfOnboardDropdown()
    const dispatch = useDispatch()
    const {
        prospect_id,
        email,
        token,
        personalDetails,
        dropDowns: { countries, cities, states }
    } = useSelector((state) => state.selfOnboard)

    // eslint-disable-next-line
    const countryOptions = useCallback(
        countries.map((country) => ({
            label: country.at(1),
            value: country.at(0)
        })),
        [countries]
    )

    // eslint-disable-next-line
    const phoneCodeOptions = useCallback(
        countries.map((country) => ({
            label: `${country.at(1)} (${country.at(2)})`,
            value: country.at(0)
        })),
        [countries]
    )

    const handleNext = async (data) => {
        const payload = {
            ...data,
            prospect_id,
            email,
            token,
            city_id: data.city_id?.value || data.city_id?.label
        }

        const resp = await post({
            apiCaller: updateSelfOnboardProspect,
            payload
        })

        if (resp.status === 500) {
            toast.error("Something went wrong. Try contacting support teams")
            return
        }

        dispatch(goToNextPersonalDetails(data))
    }

    const handleCountryChange = async (countryId) => {
        const [data, status] = await get({
            apiCaller: fetchStateByCountry,
            params: { country_id: countryId, token }
        })

        if (status !== 200) {
            toast.error("Failed to load state")
            return
        }

        const options = data.values.map((state) => ({
            label: state.at(1),
            value: state.at(0)
        }))
        dispatch(setStates(options))
    }

    const handleStateChange = async (stateId) => {
        const [data, status] = await get({
            apiCaller: fetchCityByState,
            params: { state_id: stateId, token }
        })

        if (status !== 200) {
            toast.error("Failed to load cities")
            return
        }

        const options = data.values.map((city) => ({
            label: city.at(1),
            value: city.at(0)
        }))
        dispatch(setCities(options))
    }

    const schemaList = [
        {
            name: "first_name",
            label: "First name",
            required: true
        },
        {
            name: "last_name",
            label: "Last name",
            required: true
        },
        {
            name: "mobile",
            type: "phone",
            label: "Mobile number",
            codeName: "mobile_country_code",
            codeLabel: "Dailing code",
            codeOptions: phoneCodeOptions || []
        },
        {
            name: "dob",
            label: "Date of birth",
            type: "date",
            required: true
        },
        {
            name: "address_1",
            label: "Address Line 1",
            required: true
        },
        {
            name: "address_2",
            label: "Address Line 2",
            required: true
        },
        {
            name: "country_id",
            label: "Country of Residence",
            options: countryOptions || [],
            type: "select",
            required: true,
            handleChange: handleCountryChange
        },
        {
            name: "state_id",
            label: "State",
            options: states,
            type: "select",
            required: true,
            handleChange: handleStateChange
        },

        {
            name: "city_id",
            label: "City",
            type: "creatable",
            options: cities,
            required: true
        },
        {
            name: "postal_code",
            label: "Postal code",
            required: true
        }
    ]

    return (
        <FormWrapper title={title} subtitle={subtitle}>
            <Formik
                initialValues={personalDetails}
                validationSchema={validationSchema}
                onSubmit={handleNext}
            >
                <Form>
                    {schemaList.map((schema) => {
                        return schema.type === "phone" ? (
                            <Box
                                key={schema.name}
                                sx={{ mb: 4, display: "flex" }}
                            >
                                <SelectWrapper
                                    size="normal"
                                    name={schema.codeName}
                                    label={schema.codeLabel}
                                    options={schema.codeOptions}
                                    fullWidth={false}
                                    required
                                    sx={{ width: "200px" }}
                                />
                                <TextFieldWrapper
                                    size="normal"
                                    name={schema.name}
                                    label={schema.label}
                                    required
                                />
                            </Box>
                        ) : (
                            <Box mb={4} key={schema.name}>
                                <FormField
                                    size="normal"
                                    name={schema.name}
                                    label={schema.label}
                                    type={schema.type}
                                    handleChange={schema.handleChange}
                                    options={schema.options}
                                    required={schema.required}
                                />
                            </Box>
                        )
                    })}
                    <FormAction isLoading={isLoading} />
                </Form>
            </Formik>
        </FormWrapper>
    )
}
