/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import {
    setTerminalHistory,
    setSelectedTerminalResponse
} from "../../../../redux/slices/ChatGptSlice"

import {
    Typography,
    Grid,
    Paper,
    CircularProgress,
    Tooltip
} from "@mui/material"
import { styled } from "@mui/material/styles"
import RefreshIcon from "@mui/icons-material/Refresh"
import { toast } from "react-toastify"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"

const Item = styled(Grid)(({ theme }) => ({
    backgroundColor: "#201F23",
    ...theme.typography.body2,
    textAlign: "left",
    color: "#BFBFBF",
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "8px"
}))

export default function SidebarAgent() {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const terminalHistory = useSelector(
        (state) => state.chatGpt.terminalHistory
    )
    const [isRefreshing, setIsRefreshing] = useState(false)

    const handleRefresh = () => {
        setIsRefreshing(true)
        fetch(url, { method: "GET", headers: headers, credentials: "include" })
            .then((response) => {
                return response.json()
            })
            .then((res_json) => {
                let json_response = JSON.parse(res_json)

                // Convert the object into an array of key-value pairs
                let keyValueArray = Object.entries(json_response)

                // Sort the array based on the startdate field of the first object in each child array
                keyValueArray.sort((a, b) => {
                    let dateA = new Date(a[1][0].startdate)
                    let dateB = new Date(b[1][0].startdate)
                    return dateB - dateA // sort in ascending order
                })

                // Convert the sorted array back into an object
                json_response = Object.fromEntries(keyValueArray)

                dispatch(setTerminalHistory(json_response))

                setIsRefreshing(false)
            })
            .catch((error) => {
                console.error("Error refreshing data:", error)
                toast.error(
                    "Error refreshing data, please contact your administrator. Error Code 708"
                )
                setIsRefreshing(false)
            })
    }

    const handleDelete = (execution) => {
        setIsRefreshing(true)
        fetch(base_url + "terminal_event/?execution_id=" + execution, {
            method: "DELETE",
            headers: headers,
            credentials: "include"
        })
            .then((response) => {
                handleRefresh()
                setIsRefreshing(false)
            })
            .catch((error) => {
                console.error("Error refreshing data:", error)
                toast.error(
                    "Error deleting data, please contact your administrator. Error Code 709"
                )
                setIsRefreshing(false)
            })
        console.log({ execution })
    }

    // API settings
    let auth_header = ""
    if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
        const user = window.localStorage.getItem("user")
        if (user) {
            auth_header = `Bearer ${JSON.parse(user).idToken}`
        }
    }
    let headers = {
        authorization: auth_header,
        env: process.env.REACT_APP_DOMAIN_NAME,
        "content-type": "application/json"
    }
    const base_url = "https://gaia.kairoswealth.com/"
    const url = base_url + "get_terminal/"

    useEffect(() => {
        console.log("Fetching terminal history...")
        fetch(url, { method: "GET", headers: headers, credentials: "include" })
            .then((response) => {
                return response.json()
            })
            .then((res_json) => {
                const originalObject = JSON.parse(res_json)
                // Convert the object into an array of key-value pairs
                const keyValueArray = Object.entries(originalObject)

                // Reverse the order of the array
                const reversedArray = keyValueArray.reverse()

                // Reconstruct the object from the reversed array
                const reversedObject = Object.fromEntries(reversedArray)

                dispatch(setTerminalHistory(reversedObject))
            }).catch(error => console.log(error))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            sx={{ marginX: 2, height: "100%" }}
        >
            <Grid
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <Typography color="white">{t("Gaia Agent History")}</Typography>
                <Grid display="flex" flexDirection="row">
                    {isRefreshing ? (
                        <CircularProgress
                            size="1rem"
                            color="inherit"
                            sx={{ marginLeft: 2, marginTop: 0.5 }}
                        />
                    ) : null}
                    <RefreshIcon
                        onClick={handleRefresh}
                        sx={{ color: "#eeeeee", cursor: "pointer" }}
                    />
                </Grid>
            </Grid>
            <Typography variant="caption" mb={2} sx={{ color: "#eeeeee" }}>
                {t(
                    "Click refresh button to update status of agents in progress"
                )}
            </Typography>
            {/** Show the history */}
            {Object.keys(terminalHistory).length > 0 ? (
                <Grid sx={{ height: "100%" }}>
                    {Object.entries(terminalHistory).map((execution, index) => {
                        //console.log({execution}); // this is the object for each execution. [0] is execution_id, [1] is event line
                        let question = ""
                        let isCompleted = false
                        execution[1].forEach((event_line) => {
                            if (event_line.type === "QUESTION") {
                                question = event_line.text
                            }
                            if (event_line.type === "VERDICT") {
                                isCompleted = true
                            }
                        })
                        return (
                            <Item
                                component={motion.div}
                                whileHover={{
                                    translateX: 5,
                                    transition: { duration: 0.3 }
                                }}
                                key={index}
                                sx={{
                                    border: "1px solid gray",
                                    marginBottom: 1
                                }}
                            >
                                <Grid
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Tooltip
                                        title={isCompleted ? "" : "In Progress"}
                                    >
                                        <Grid
                                            sx={{ cursor: "pointer" }}
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-start"
                                            onClick={() => {
                                                dispatch(
                                                    setSelectedTerminalResponse(
                                                        execution
                                                    )
                                                )
                                            }}
                                        >
                                            {console.log({ isCompleted })}
                                            <Grid
                                                id={"bookmarkstatus" + index}
                                                sx={{
                                                    width: "3px",
                                                    minWidth: "3px",
                                                    minHeight: "100%",
                                                    backgroundColor: isCompleted ? "#008000" : "#FFFF00",
                                                    marginRight: 1
                                                }}
                                            >&nbsp;</Grid>
                                            {question}
                                        </Grid>
                                    </Tooltip>
                                    <DeleteOutlineIcon
                                        fontSize="small"
                                        sx={{
                                            cursor: "pointer",
                                            color: "#808080",
                                            "&:hover": { color: "#ffffff" }
                                        }}
                                        onClick={() =>
                                            handleDelete(execution[0])
                                        }
                                    />
                                </Grid>
                            </Item>
                        )
                    })}
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    )
}
