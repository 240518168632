import { Formik, Form } from "formik"

import * as Yup from "yup"
import FormWrapper from "../../components/FormWrapper"
import FileInputWrapper from "../../components/FileInputWrapper"
import SectionWrapper from "./SectionWrapper"
import { checkPdfFormat, checkPdfSize } from "../../utils"
import { useDispatch, useSelector } from "react-redux"
import { goToNextEntityKyc } from "redux/slices/selfOnboardSlice"
import FormAction from "../../components/FormAction"

const title = "KYC documents"
const subtitle =
    "To comply with regulatory requirements, we need you to upload Know Your Customer (KYC) documents. These documents will help verify your identity."

const validationSchema = Yup.object({
    proofOfAddress: Yup.mixed()
        .required("document is required")
        .test(
            "fileformat",
            "Supported document formats: pdf,jpg,jpeg,png",
            checkPdfFormat
        )
        .test(
            "filesize",
            "the document size must not exceed 9mb",
            checkPdfSize
        ),
    proofOfUboIdentity: Yup.mixed()
        .required("document is required")
        .test(
            "fileformat",
            "Supported document formats: pdf,jpg,jpeg,png",
            checkPdfFormat
        )
        .test("filesize", "the document size must not exceed 9mb", checkPdfSize),
    certificateOfIncorporation: Yup.mixed()
        .required("document is required")
        .test(
            "fileformat",
            "Supported document formats: pdf,jpg,jpeg,png",
            checkPdfFormat
        )
        .test("filesize", "the document size must not exceed 9mb", checkPdfSize)
})

export default function IndividualKyc() {
    const dispatch = useDispatch()
    const { entityKycDetails } = useSelector((state) => state.selfOnboard)

    const handleNext = (data) => {
        dispatch(goToNextEntityKyc(data))
    }

    return (
        <FormWrapper title={title} subtitle={subtitle}>
            <Formik
                initialValues={entityKycDetails}
                validationSchema={validationSchema}
                onSubmit={handleNext}
            >
                <Form>
                    <SectionWrapper title="Certificate of Incorporation or Trust Deed">
                        <FileInputWrapper name="certificateOfIncorporation" />
                    </SectionWrapper>
                    <SectionWrapper title="Proof Of Identity of all UBO">
                        <FileInputWrapper name="proofOfUboIdentity" />
                    </SectionWrapper>
                    <SectionWrapper title="Proof of Address">
                        <FileInputWrapper name="proofOfAddress" />
                    </SectionWrapper>
                    <FormAction />
                </Form>
            </Formik>
        </FormWrapper>
    )
}
