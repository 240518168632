import { useTranslation } from "react-i18next"
import { Grid, Divider, Button, Stack } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { supportedLanguages } from "../constants"

const SidebarFooter = ({
    handleHistoryClick,
    handleNotificationsClick,
    handleChangeLanguage,
    handleHelp
}) => {
    const { t, i18n } = useTranslation()
    return (
        <Grid container pb={2}>
            <Divider
                variant="fullWidth"
                sx={{
                    borderColor: "gray.600",
                    mt: 2
                }}
            />
            <Grid xs={12} px={1}>
                <Grid container mb={1} spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                background: "#6442C4"
                            }}
                            onClick={handleHistoryClick}
                        >
                            {t("History")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                color: "white",
                                border: "2px solid #6442C4",
                                background: "transparent",
                                textTransform: "none"
                            }}
                            onClick={handleNotificationsClick}
                        >
                            {t("Notifications")}
                        </Button>
                    </Grid>
                </Grid>
                {/** Language Selector */}
                <Stack direction="row" justifyContent="space-between">
                    <select
                        style={{
                            color: "#bfbfbf",
                            backgroundColor: "#313034",
                            padding: 2,
                            borderRadius: "8px"
                        }}
                        onChange={(e) => handleChangeLanguage(e.target.value)}
                        defaultValue={i18n.language}
                    >
                        {supportedLanguages.map((lang) => {
                            return (
                                <option
                                    value={lang}
                                    sx={{
                                        cursor: "pointer",
                                        color: "#BFBFBF"
                                    }}
                                >
                                    {lang}
                                </option>
                            )
                        })}
                    </select>
                    <HelpOutlineIcon
                        fontSize="medium"
                        sx={{
                            cursor: "pointer",
                            color: "#8E8EA0",
                            "&:hover": { color: "#ffffff" }
                        }}
                        onClick={() => handleHelp()}
                    />
                </Stack>
            </Grid>
        </Grid>
    )
}

export default SidebarFooter
