import { Grid, Button } from "@mui/material"
import { useTranslation } from "react-i18next"

const FinancialFilterTabs = ({ selectedButton, handleButtonClick }) => {
    const { t } = useTranslation()
    const buttons = [
        {
            value: t("Stocks"),
            englishValue: "Stocks"
        },
        {
            value: t("Internal Data"),
            englishValue: "Internal Data"
        },
        {
            value: t("Portfolio"),
            englishValue: "Portfolio"
        },
        {
            value: t("Agent"),
            englishValue: "Agent"
        }
    ]
    return (
        <Grid container spacing={2} px={1} mt={1}>
            {buttons.map((label, index) => (
                <Grid item xs={12} lg={6}>
                    <Button
                        key={index}
                        fullWidth={true}
                        sx={{
                            textTransform: "none",
                            color: "white",
                            borderColor: "gray.500",
                            ":hover": {
                                boxShadow: "0 0 0 2px #6442c4",
                                backgroundColor:
                                    selectedButton === label.value
                                        ? "#6442c4"
                                        : ""
                            },
                            backgroundColor:
                                selectedButton === label.value ? "#6442c4" : ""
                        }}
                        onClick={() => handleButtonClick(label)}
                        variant={
                            selectedButton === label.value
                                ? "contained"
                                : "outlined"
                        }
                    >
                        {label.value}
                    </Button>
                </Grid>
            ))}
        </Grid>
    )
}
export default FinancialFilterTabs
