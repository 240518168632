import * as React from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import {
    setBookmark,
    setNotificationClick,
    setReceivedNotification,
    setNotificationCount,
    setPage,
    setSelectedButton,
    setTickersList,
    setFolders,
    setDisplayHelpModal,
    setStockGraphPeriod,
    setEnglishSelectedButton
} from "../../../redux/slices/ChatGptSlice"
import { getRmNotificationList } from "../../../services/index"

import logo from "../../../theme/images/GuestKairos Logo.svg"

import { Box, Divider, Grid, Tabs, Tab, AppBar } from "@mui/material"
import SwipeableViews from "react-swipeable-views"

import SidebarStocks from "./Sidebar/stocks"
import SidebarInternalData from "./Sidebar/internalData"
import Modals from "./Sidebar/modals"
import SidebarPortfolio from "./Sidebar/portfolio"
import SidebarAgent from "./Sidebar/agent"
import Bookmarks from "./Sidebar/bookmarks"
import { changeLanguage } from "i18next"
import FinancialFilterTabs from "./Components/FinancialFilterTabs"
import SidebarFooter from "./Components/SidebarFooter"

function TabPanel(props) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`
    }
}

export default function Sidebar() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [value, setValue] = React.useState(0)

    const user = JSON.parse(localStorage.getItem("user"))
    const hniUser = user?.userTypeId?.description
    const isHni =
        hniUser === "Client Head" || hniUser === "Client Family Member"
            ? true
            : false
    const targetValue = useSelector((state) => state.nav.targetValue)
    const bookmarkList = useSelector((state) => state?.chatGpt?.bookmarkList)
    console.log("res", bookmarkList)
    const selectedButton = useSelector((state) => state.chatGpt.selectedButton)
    const englishSelectedButton = useSelector(
        (state) => state.chatGpt.englishSelectedButton
    )
    const stockGraphPeriod = useSelector(
        (state) => state.chatGpt.stockGraphPeriod
    )

    if (isHni && targetValue === "llm") {
        navigate("/chatgpt")
    }

    const handleChangeLanguage = async (dest_lng) => {
        await changeLanguage(dest_lng)
        const updatedSelectedButton = t(englishSelectedButton, {
            lng: dest_lng
        })
        dispatch(setSelectedButton(updatedSelectedButton))
    }

    const handleButtonClick = (buttonIndex) => {
        // Update the selected button index
        dispatch(setSelectedButton(buttonIndex.value))
        dispatch(setEnglishSelectedButton(buttonIndex.englishValue))
    }

    useEffect(() => {
        // initialize redux variables that have not been translated
        if (selectedButton === undefined) {
            dispatch(setSelectedButton(t("Stocks")))
        }
        if (stockGraphPeriod === undefined) {
            dispatch(setStockGraphPeriod(t("daily")))
        }

        // Check if the data is already in local storage
        let storedData = localStorage.getItem("tickersList")
        if (
            storedData !== null &&
            storedData !== undefined &&
            storedData !== "undefined"
        ) {
            // If data is in local storage, parse and set it in the state
            dispatch(setTickersList(JSON.parse(storedData)))
        } else {
            // If data is not in local storage, fetch it
            queryAPI("tickers", "GET", "", "").then((res_tickers) => {
                localStorage.setItem(
                    "tickersList",
                    JSON.stringify(res_tickers.tickers)
                )
                dispatch(setTickersList(res_tickers.tickers))
            })
        }
        queryAPI("indexes", "GET", "", "")
            .then((res_indexes) => {
                if (res_indexes[0].length > 0) {
                    let options = [""]
                    res_indexes[0].forEach((index) => {
                        options.push(index.payload["index_id"])
                    })
                    dispatch(setFolders(options))
                }
            })
            .catch((e) => {
                console.log("error: ", e)
            }) // eslint-disable-next-line
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        if (newValue === 1) {
            navigate("/chatgpt")
        } else {
            navigate("/kairosbot")
        }
    }

    const handleChangeIndex = (index) => {
        setValue(index)
    }

    const handleHistoryClick = () => {
        dispatch(setPage(0))
        if (targetValue === "llm") {
            navigate("/kairosbot/history")
        } else {
            navigate("/chatgpt/history")
        }
        dispatch(setBookmark(true))
    }

    const handleNotificationsClick = () => {
        if (user?.userTypeId?.description !== "Entity RM") {
            getRmNotificationList(user?.userId, targetValue).then((res) => {
                if (res?.data?.status) {
                    dispatch(setReceivedNotification(res?.data?.body))
                    dispatch(setNotificationCount(0))
                }
            })
        }
        dispatch(setNotificationClick(true))
    }

    const handleHelp = () => {
        dispatch(setDisplayHelpModal(true))
    }

    const queryAPI = (object, method, query, payload) => {
        let auth_header = ""
        if (JSON.parse(window.localStorage.getItem("user"))?.idToken) {
            const user = window.localStorage.getItem("user")
            if (user) {
                auth_header = `Bearer ${JSON.parse(user).idToken}`
            }
        }

        const env = process.env.REACT_APP_DOMAIN_NAME

        const base_api_url = "https://gaia.kairoswealth.com/"
        let headers = {}
        if (object === "document" && method === "POST") {
            headers = {
                authorization: auth_header,
                env: env
                //"content-type": "multipart/form-data"
            }
        } else {
            headers = {
                authorization: auth_header,
                env: env,
                "content-type": "application/json"
            }
        }
        const url = base_api_url + object + "/" + query
        let data = {}
        if (payload !== "") {
            data = {
                method: method,
                headers: headers,
                body: payload
            }
        } else {
            data = {
                method: method,
                headers: headers
            }
        }
        return fetch(url, data)
            .then((response) => {
                if (response.status === 200) {
                    const res = response.json()
                    return res
                } else {
                    return 1
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error)
            })
    }

    return (
        <Grid
            container
            id="SidebarContainer"
            sx={{
                rowGap: 2,
                bgcolor: "#000",
                overflowY: "none",
                maxHeight: "100vh"
            }}
            width="100%"
            height="100%"
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
        >
            {/** Group all the top elements */}
            <Grid
                display="flex"
                flexDirection="column"
                width="100%"
                wrap="nowrap"
                maxHeight="100%"
                sx={{ overflowY: "auto" }}
            >
                {/** logo */}
                <Grid item sx={{ marginX: "auto", mt: 3, mb: 1 }}>
                    <img
                        src={logo}
                        style={{ width: "125px", cursor: "pointer" }}
                        alt="logoIcon"
                        onClick={() => {
                            navigate("/dashboard")
                        }}
                    />
                </Grid>
                <Divider
                    variant="fullWidth"
                    sx={{ borderColor: "gray.600", mt: 2 }}
                />

                {/** Financial / lifestyle selector */}
                <Box sx={{ width: "100%" }}>
                    <AppBar position="static" sx={{ backgroundColor: "#000" }}>
                        {!isHni ? (
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                aria-label="finacial and lifestyle selector"
                                sx={{
                                    backgroundColor: "gray.900"
                                }}
                                centered
                            >
                                <Tab
                                    sx={{ textTransform: "none" }}
                                    label={t("Financial")}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    sx={{ textTransform: "none" }}
                                    label={t("Lifestyle")}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        ) : (
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="financial and lifestyle selector"
                                sx={{
                                    backgroundColor: "gray.900"
                                }}
                            >
                                <Tab
                                    sx={{ textTransform: "none" }}
                                    label={t("Lifestyle")}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        )}
                    </AppBar>
                    {!isHni ? (
                        <SwipeableViews
                            axis={"x"}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                            height="100%"
                        >
                            {/** Financial tab section (only accessible by RM */}
                            <TabPanel
                                value={value}
                                index={0}
                                dir={"x"}
                                height="100%'"
                            >
                                <Grid container flexDirection="column">
                                    {/** Financial menu */}
                                    <FinancialFilterTabs
                                        selectedButton={selectedButton}
                                        handleButtonClick={handleButtonClick}
                                    />
                                    <Divider
                                        variant="fullWidth"
                                        sx={{ borderColor: "gray.600", my: 3 }}
                                    />

                                    {selectedButton === t("Stocks") && (
                                        <SidebarStocks />
                                    )}

                                    {selectedButton === t("Internal Data") && (
                                        <SidebarInternalData
                                            queryAPI={queryAPI}
                                        />
                                    )}

                                    {selectedButton === t("Portfolio") && (
                                        <SidebarPortfolio queryAPI={queryAPI} />
                                    )}

                                    {/** bookmark is shown for all except agent */}
                                    {selectedButton !== t("Agent") && (
                                        <>
                                            <Divider
                                                variant="fullWidth"
                                                sx={{
                                                    borderColor: "gray.600",
                                                    mb: 2
                                                }}
                                            />
                                            <Bookmarks
                                                bookmarkList={bookmarkList}
                                            />
                                        </>
                                    )}
                                    {selectedButton === t("Agent") && (
                                        <SidebarAgent queryAPI={queryAPI} />
                                    )}
                                </Grid>
                            </TabPanel>
                            {/** Lifestyle tab section */}
                            <TabPanel value={value} index={1} dir={"x"}>
                                {/** Bookmarks section */}
                                <Bookmarks bookmarkList={bookmarkList} />
                            </TabPanel>
                        </SwipeableViews>
                    ) : (
                        <>
                            {/** Lifestyle tab section */}
                            <Bookmarks bookmarkList={bookmarkList} />
                        </>
                    )}
                </Box>
            </Grid>
            <SidebarFooter
                handleHelp={handleHelp}
                handleChangeLanguage={handleChangeLanguage}
                handleNotificationsClick={handleNotificationsClick}
                handleHistoryClick={handleHistoryClick}
            />

            {/** Component containing all the modals */}
            <Modals queryAPI={queryAPI} />
        </Grid>
    )
}
