import { Formik, Form } from "formik"
import { CheckboxWrapper } from "components/common/Ui/Form"

import * as Yup from "yup"
import FormWrapper from "../components/FormWrapper"
import { Typography, Box } from "@mui/material"
import FormAction from "../components/FormAction"
import { useSelector } from "react-redux"
import { completeSelfRegistration } from "services/prospectsServices"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

// ** constants
const FAILED_MSG =
    "We encountered an issue completing your onboarding. Please try again."
const SUCCESS_MSG = "Welcome! You've completed the onboarding process."
const title = "Review & Submit"
const subtitle =
    "Please confirm that all the information provided is accurate and complete. If any details need to be updated, feel free to go back and make the necessary changes before submission."
const terms =
    "I hereby confirm that the information I have provided is true and accurate."

const validationSchema = Yup.object({
    declaration: Yup.boolean()
        .oneOf(
            [true],
            "Please confirm that the information you have provided is true and accurate by checking the box."
        )
        .required(
            "Please confirm that the information you have provided is true and accurate by checking the box."
        )
})

export default function IndividualKyc() {
    const { token } = useSelector((state) => state.selfOnboard)
    const { post, isLoading } = useSelfOnboardApi()
    const navigate = useNavigate()

    const handleOnboardCompletion = async () => {
        const [data, status] = await post({
            apiCaller: completeSelfRegistration,
            payload: { token }
        })

        console.log(data, status)
        if (status !== 200) {
            toast.error(FAILED_MSG, { toastId: status })
            return
        }
        toast.success(SUCCESS_MSG, {
            toastId: status
        })
        navigate("/onboard-success")
    }

    return (
        <FormWrapper title={title} subtitle={subtitle}>
            <Formik
                initialValues={{}}
                validationSchema={validationSchema}
                onSubmit={handleOnboardCompletion}
            >
                <Form>
                    <Box
                        sx={{
                            px: 4,
                            py: 2,
                            mb: 2,
                            borderRadius: "12px",
                            border: "1px solid",
                            borderColor: "gray.300"
                        }}
                    >
                        <CheckboxWrapper
                            id="declaration"
                            name="declaration"
                            label="Declaration"
                        />
                        <Typography variant="body2" sx={{ color: "gray.500" }}>
                            {terms}
                        </Typography>
                    </Box>
                    <FormAction isLoading={isLoading} />
                </Form>
            </Formik>
        </FormWrapper>
    )
}
