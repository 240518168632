import { fetchOnboardDropdowns } from "services/prospectsServices"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
import { storeDropdowns } from "redux/slices/selfOnboardSlice"

export const useSelfOnboardDropdown = () => {
    const { get } = useSelfOnboardApi()
    const dispatch = useDispatch()
    const {
        token,
        dropDowns: { riskAppetites }
    } = useSelector((state) => state.selfOnboard)

    useEffect(() => {
        if (riskAppetites.length > 0) return

        const _fetchData = async () => {
            const resp = await get({
                apiCaller: fetchOnboardDropdowns,
                params: { token }
            })
            const {
                countries,
                risk_appetite_values,
                financial_goals_values,
                industry_preference_values
            } = resp?.at(0)
            const financialGoals = financial_goals_values.map((finGoal) => ({
                label: finGoal.at(1),
                value: finGoal.at(0)
            }))
            const industryPreferences = industry_preference_values.map(
                (indPref) => ({ label: indPref.at(1), value: indPref.at(0) })
            )
            const riskAppetites = risk_appetite_values.map((riskAppetite) => ({
                label: riskAppetite.at(1),
                value: riskAppetite.at(0)
            }))
            const payload = {
                countries,
                financialGoals,
                industryPreferences,
                riskAppetites
            }
            dispatch(storeDropdowns(payload))
        }
        _fetchData()
    }, [riskAppetites.length, dispatch, get, token])
}
