import Accordion from "@mui/material/Accordion"
import { Button } from "components/common/Ui/Form"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { IconButton, Typography, Box } from "@mui/material"
import { DeleteOutlined } from "@mui/icons-material"

import { ReactComponent as ExpandIcon } from "theme/images/AccordianExpandIcon.svg"
import AddForm from "./AddForm"

const RelatedPartyDetails = ({
    party_name,
    party_type,
    email,
    address,
    country_id,
    is_pep
}) => {
    const handleUpdate = (data) => {
        console.log(data)
    }

    return (
        <Accordion
            sx={{
                "&:before": {
                    display: "none"
                },
                marginTop: 1,
                px: 2,
                borderRadius: 3,
                borderWidth: 1,
                borderColor: "#EBEBEB",
                borderStyle: "solid",
                boxShadow: "0px 2px 10px 0px #CDCDCD1A"
            }}
        >
            <AccordionSummary expandIcon={<ExpandIcon />}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        pr: 4
                    }}
                >
                    <Typography variant="body1">{party_type}</Typography>
                    <Typography variant="body1">{party_name}</Typography>
                    <Typography variant="body1">{email}</Typography>
                    <IconButton onClick={(e) => {e.stopPropagation()}}>
                        <DeleteOutlined sx={{ color: "red.600" }} />
                    </IconButton>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <AddForm
                    handleSubmit={handleUpdate}
                    defaultValues={{
                        party_name,
                        party_type,
                        email,
                        address,
                        is_pep,
                        country_id
                    }}
                >
                    <FormAction />
                </AddForm>
            </AccordionDetails>
        </Accordion>
    )
}

const FormAction = ({ isLoading }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                pt: 1,
                justifyContent: "flex-end",
                alignItems: "center"
            }}
        >
            <Button variant="contained" type="submit" disabled={isLoading}>
                {isLoading ? "Saving..." : "Save"}
            </Button>
        </Box>
    )
}
export default RelatedPartyDetails
