import { initializeApp } from "firebase/app"
import {
    getMessaging,
    getToken,
    onMessage,
    isSupported
} from "firebase/messaging"
// const firebasekey = "AIzaSyB1x-rUz-WAHZXHzhLP3stT0Vr8BUL1jiI"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: "helix-eabc5.firebaseapp.com",
    projectId: "helix-eabc5",
    storageBucket: "helix-eabc5.appspot.com",
    messagingSenderId: "645656578594",
    appId: "1:645656578594:web:281db05286a3036b81187b",
    measurementId: "G-5VCJV11V4X"
}
let vapidKey =
    "BK8SYxqnd5FUcXVMTsnOl5a7Q5StZA0TvAE92iEAZhvnVYn92QxC71JKzBdU6cnZfNP00lQ3__4m2TaDMEVSm2I"

const firebaseApp = isSupported().then(() => {
    return initializeApp(firebaseConfig)
})
const messaging = isSupported()
    .then(() => {
        return getMessaging(firebaseApp)
    })
    .catch((error) => {
        console.error(error)
    })

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, { vapidKey: vapidKey })
        .then((currentToken) => {
            let stateObj = { available: false, fbToken: "" }
            if (currentToken) {
                console.log("current token for client: ", currentToken)
                stateObj.available = true
                stateObj.fbToken = currentToken
                setTokenFound(() => ({ ...stateObj }))
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
                return currentToken
            } else {
                console.log(
                    "No registration token available. Request permission to generate one."
                )
                setTokenFound(() => ({ ...stateObj }))
                // shows on the UI that permission is required
                Notification.requestPermission().then((permission) => {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        // eslint-disable-next-line no-unused-vars
                        const notification = new Notification("Hi there!")
                        // …
                    }
                })
            }
        })
        .catch((err) => {
            console.log("An error occurred while retrieving token. ", err)
            // catch error while creating client token
        })
}

export const onMessageListener = (osInfo) =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })

//
