import { Box, Typography } from "@mui/material"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
const RelatedPartyWrapper = ({ title, subtitle, children }) => {
    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                py: 4,
                px: 6,
                borderRadius: 1,
                mt: 4
            }}
        >
            <Box
                sx={{
                    pb: 3,
                    borderBottom: "1px solid",
                    borderColor: "gray.200",
                    mb: 4
                }}
            >
                <Typography variant="h3" sx={{ fontWeight: 500, mb: 1 }}>
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ color: "gray.500", maxWidth: "90ch" }}
                >
                    {subtitle}
                </Typography>
                <Box
                    sx={{
                        color: "gray.500",
                        maxWidth: "90ch",
                        bgcolor: "#E3F2FD",
                        border: "1px solid #2196F3",
                        borderRadius: "12px",
                        p: 2,
                        mt: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: 1
                    }}
                >
                    <ErrorOutlineIcon sx={{ color: "#2196F3" }} />
                    <Typography variant="body2" sx={{}}>
                        KYC documents for each related party will be required in
                        the next step.
                    </Typography>
                </Box>
            </Box>
            {children}
        </Box>
    )
}
export default RelatedPartyWrapper
