import { Add } from "@mui/icons-material"
import RelatedPartyWrapper from "./RelatedPartyWrapper"
import { Button } from "components/common/Ui/Form"
import { useState } from "react"
import AddForm from "./AddForm"
import { Box, Typography } from "@mui/material"
import RelatedPartyDetails from "./RelatedPartyDetails"
import { useDispatch, useSelector } from "react-redux"
import { goBack, goToNextRelatedParty } from "redux/slices/selfOnboardSlice"
import { NavigateBefore, NavigateNext } from "@mui/icons-material"
import { savePartyDetails } from "redux/slices/selfOnboardSlice"

const title = "Related party details"
const subtitle =
    "Please add UBO, Shareholders owner >10% of entity, Signatories, Principal"

const defaultValues = {
    party_name: "",
    party_type: "",
    country_id: "",
    email: "",
    address: "",
    is_pep: "N"
}
export default function InvestorType() {
    const [showAddForm, setShowAddForm] = useState(false)
    const dispatch = useDispatch()
    const { relatedPartyDetails } = useSelector((state) => state.selfOnboard)

    const handleAddSubmit = (data) => {
        dispatch(savePartyDetails(data))
    }

    return (
        <RelatedPartyWrapper title={title} subtitle={subtitle}>
            <Button
                startIcon={Add}
                variant="outlined"
                onClick={() => setShowAddForm(true)}
            >
                Add Related Party
            </Button>
            {showAddForm && (
                <Box
                    sx={{
                        mt: 4,
                        border: "1px solid",
                        borderColor: "gray.200",
                        p: 3,
                        borderRadius: "12px"
                    }}
                >
                    <AddForm
                        handleSubmit={handleAddSubmit}
                        defaultValues={defaultValues}
                    >
                        <AddFormAction
                            handleCancelClick={() => setShowAddForm(false)}
                        />
                    </AddForm>
                </Box>
            )}
            <Box
                sx={{
                    border: "1px solid",
                    borderColor: "gray.200",
                    p: 3,
                    borderRadius: "12px",
                    mt: 2
                }}
            >
                {relatedPartyDetails.length > 0 ? (
                    <>
                        {relatedPartyDetails.map((party) => (
                            <RelatedPartyDetails key={party.name} {...party} />
                        ))}
                    </>
                ) : (
                    <Typography variant="h6" textAlign="center">
                        No party records
                    </Typography>
                )}
            </Box>
            <MainPageAction />
        </RelatedPartyWrapper>
    )
}

const MainPageAction = () => {
    const dispatch = useDispatch()

    const handleBackBtn = () => {
        dispatch(goBack())
    }
    const handleNext = () => {
        dispatch(goToNextRelatedParty())
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            <Button
                variant="outlined"
                onClick={handleBackBtn}
                sx={{ mr: 1 }}
                startIcon={NavigateBefore}
            >
                Back
            </Button>
            <Button
                variant="contained"
                endIcon={NavigateNext}
                onClick={handleNext}
            >
                Next
            </Button>
        </Box>
    )
}

const AddFormAction = ({ isLoading, handleCancelClick }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                pt: 1,
                justifyContent: "flex-end",
                alignItems: "center"
            }}
        >
            <Box>
                <Button
                    variant="outlined"
                    type="reset"
                    sx={{ mr: 1 }}
                    onClick={handleCancelClick}
                >
                    Cancel
                </Button>
                <Button variant="contained" type="submit" disabled={isLoading}>
                    {isLoading ? "Saving..." : "Add"}
                </Button>
            </Box>
        </Box>
    )
}
