import { Outlet } from "react-router-dom"
import { Box, Typography, Link } from "@mui/material"
import kairosLogo from "theme/images/NewSidebarIcons/logoKairosV1.svg"

const SelfOnboardLayout = () => {
    return (
        <Box
            sx={{
                backgroundColor: "gray.100",
                minHeight: "100dvh",
                display: "flex",
                flexDirection: "column",

            }}
        >
            <Box
                sx={{
                    height: "140px",
                    mb: 4,
                    display: "flex",
                    alignItems: "center",
                    backgroundImage:
                        "radial-gradient(ellipse at bottom, #643dd6 15%, #130c28 60%);"
                }}
            >
                <Typography
                    sx={{
                        fontSize: "40px",
                        color: "gray.50",
                        maxWidth: "1000px",
                        mx: "auto",
                        width: "90%",
                        pl: 2,
                        fontWeight: 700,
                        fontFamily: "The Seasons, serif"
                    }}
                >
                    Welcome to KairosWealth
                </Typography>
            </Box>
            <Box
                sx={{
                    maxWidth: "1000px",
                    mx: "auto",
                    width: "90%",
                    my: "auto"
                }}
            >
                <Outlet />
            </Box>
            <Box
                component="footer"
                sx={{
                    bgcolor: "#fff",
                    py: 2,
                    display: "flex",
                    gap: 2,
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <img src={kairosLogo} alt="kairos logo" />
                <Typography variant="body1">
                    Learn more about{" "}
                    <Link
                        underline="none"
                        sx={{
                            color: "#643dd6 !important",
                            fontStyle: "normal !important"
                        }}
                        color="primary"
                        href="https://kairoswealth.com"
                        variant="body2"
                        target="_blank"
                    >
                        kairoswealth.com
                    </Link>
                </Typography>
            </Box>
        </Box>
    )
}
export default SelfOnboardLayout
