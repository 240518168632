import { Box, FormHelperText, Typography, Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import { UploadFile } from "@mui/icons-material"
import { useFormikContext, useField } from "formik"

const FileInputWrapper = ({ name, accept = ".pdf,.jpg,jpeg,.png" }) => {
    const [field, meta] = useField(name)

    const { setFieldValue, setFieldTouched } = useFormikContext()

    const handleChange = ({ target: { files } }) => {
        if (files[0]) {
            setFieldValue(name, files[0])
            setFieldTouched(name, true, false)
        }
    }

    return (
        <Box
            component="section"
            sx={{
                border: "1px solid",
                borderColor: "gray.200",
                p: 1,
                px: 2,
                borderRadius: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}
        >
            <Box>
                <FileInfo
                    name={field?.value?.name}
                    sizeInB={field?.value?.size}
                    isError={!!meta.error && meta.touched}
                />
                {meta.error && meta.touched && (
                    <FormHelperText error>{meta?.error}</FormHelperText>
                )}
            </Box>
            <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<UploadFile />}
                sx={{
                    textTransform: "none",
                    padding: "8px 30px",
                    borderRadius: "999px",
                    borderColor: "#643DD6",
                    color: "#643DD6",
                    "&:hover": {
                        borderColor: "#643DD6",
                        backgroundColor: "#f0ecfb"
                    },
                    fontWeight: 600
                }}
            >
                Document
                <VisuallyHiddenInput
                    type="file"
                    name={name}
                    onChange={handleChange}
                    accept={accept}
                />
            </Button>
        </Box>
    )
}

const FileInfo = ({ name, sizeInB}) => {
    let sizeInKB = null
    if (!!sizeInB) {
        sizeInKB = (sizeInB / 1024).toFixed(1)
    }

    return (
        <Box
            sx={{
                gap: 1,
                display: "flex",
                alignItems: "center",
                height: "50px",
                color: "gray.700",
                maxWidth: "440px"
            }}
        >
            <Typography
                component="p"
                sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "40ch",
                    fontWeight: 500,
                    fontSize: "14px"
                }}
            >
                {name || "No document selected"}
            </Typography>
            {sizeInKB && (
                <Typography
                    component="span"
                    sx={{ color: "gray.400", fontSize: "12px" }}
                >
                    {sizeInKB} KB
                </Typography>
            )}
        </Box>
    )
}

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1
})

export default FileInputWrapper
