export const reactRouterUtility = {
    navigate: null,
    location: null,
    forceUpdate: null
}

const ROLES = {
    RM: "ENTITY RM",
    HNI: ["CLIENT HEAD", "CLIENT FAMILY MEMBER"]
}
const user = JSON.parse(localStorage.getItem("user"))

// will return RM | HNI || SUPERADMIN
export const getLoggedInRole = () => {
    const loggedInUserDesc = user?.userTypeId.description.toUpperCase()

    if (ROLES.RM === loggedInUserDesc) return "RM"
    else if (ROLES.HNI.includes(loggedInUserDesc)) return "HNI"

    return "SUPERADMIN"
}

export const getClientSlugBasedOnUserType = () => {
    const role = getLoggedInRole()
    switch (role) {
        case "HNI":
            return "clientprofile"
        case "RM":
            return "myclients"
        default:
            return "clients"
    }
}
