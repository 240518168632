import { Formik, Form, Field } from "formik"

import * as Yup from "yup"
import FormWrapper from "../../components/FormWrapper"
import RadioGroupWrapper from "./RadioGroupWrapper"
import SectionWrapper from "./SectionWrapper"
import { useDispatch, useSelector } from "react-redux"
import FormAction from "../../components/FormAction"
import { goToNextEntitySuitability } from "redux/slices/selfOnboardSlice"
import { useSelfOnboardApi } from "hooks/useSelfOnboardApi"
//import { updateSelfOnboardProspect } from "services/prospectsServices"
//import { toast } from "react-toastify"

const stateOwned = [
    {
        value: "Y",
        label: "Yes",
        subtext: "My entity is a State Owned Entity."
    },
    {
        value: "N",
        label: "No",
        subtext: "My entity is not a State Owned Entity."
    }
]

const privateMarketsAllowed = [
    {
        value: "Y",
        label: "Yes",
        subtext: "My company is allowed to invest in private markets."
    },
    {
        value: "N",
        label: "No",
        subtext: "My company is not allowed to invest in private markets."
    }
]

const profOrAccredInvestor = [
    {
        value: "Y",
        label: "Yes",
        subtext: "My investors are professional or accredited investors."
    },
    {
        value: "N",
        label: "No",
        subtext: "My investors are not professional or accredited investors."
    }
]
const title = "Suitability details"
const subtitle =
    "Please provide additional information about your entity's investment eligibility and status."

const validationSchema = Yup.object({})

export default function IndividualSuitabilityDetails() {
    const { isLoading } = useSelfOnboardApi()
    const { entitySuitabilityDetails, token, prospect_id, email } = useSelector(
        (state) => state.selfOnboard
    )
    const dispatch = useDispatch()

    const handleNext = async (data) => {
        const payload = {
            ...data,
            prospect_id,
            email,
            token
        }
        console.log(payload)

        /* const resp = await post({
            apiCaller: updateSelfOnboardProspect,
            payload
        })

        if (resp.status === 500) {
            toast.error("Something went wrong. Try contacting support teams")
            return
        }*/
        dispatch(goToNextEntitySuitability(data))
    }

    // Field Schema
    return (
        <FormWrapper title={title} subtitle={subtitle}>
            <Formik
                initialValues={entitySuitabilityDetails}
                validationSchema={validationSchema}
                onSubmit={handleNext}
            >
                <Form>
                    <SectionWrapper title="Is your entity a State Owned Entity?">
                        <Field
                            name="is_state_owned"
                            component={RadioGroupWrapper}
                            options={stateOwned}
                        />
                    </SectionWrapper>
                    <SectionWrapper title="Is your company allowed to invest in private markets?">
                        <Field
                            name="is_private_allowed"
                            component={RadioGroupWrapper}
                            options={privateMarketsAllowed}
                        />
                    </SectionWrapper>

                    <SectionWrapper title="Are all your investors professional or accredited investors?">
                        <Field
                            name="is_prof"
                            component={RadioGroupWrapper}
                            options={profOrAccredInvestor}
                        />
                    </SectionWrapper>
                    <FormAction isLoading={isLoading} />
                </Form>
            </Formik>
        </FormWrapper>
    )
}
